// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketable-securities-js": () => import("../src/pages/marketable-securities.js" /* webpackChunkName: "component---src-pages-marketable-securities-js" */),
  "component---src-pages-privacy-js": () => import("../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-private-equity-js": () => import("../src/pages/private-equity.js" /* webpackChunkName: "component---src-pages-private-equity-js" */),
  "component---src-pages-real-estate-js": () => import("../src/pages/real-estate.js" /* webpackChunkName: "component---src-pages-real-estate-js" */),
  "component---src-pages-user-agreement-js": () => import("../src/pages/user-agreement.js" /* webpackChunkName: "component---src-pages-user-agreement-js" */)
}

